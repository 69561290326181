import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "../components/layout"
import StepOne from "../components/step-1";

const IndexPage = () => (
  <Layout title="home"  homepage={true}>
    <StepOne />
  </Layout>
)

export default IndexPage
